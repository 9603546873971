import React, { useEffect, useState } from "react"

import Section from "../../components/Section/Section"
import Title from "../../components/Title/Title"
import TitleList from "../../components/Title/TitleList"
import Expand from "../../components/Expand/Expand"

import "./Greeding.scss"

const Greeding = props => {
  /** デバイス */
  const [device, setDevice] = useState("sp")

  const changePath = () => {
    setDevice(document.body.clientWidth > 990 ? "pc" : "sp")
  }

  useEffect(() => {
    changePath()
    window.addEventListener("resize", changePath())
  }, [device])

  return (
    <Section id="Greeding" className={`Greeding Greeding--${device}`}>
      <div className="Greeding__inner">
        {device === "sp" && <Title title="GREEDING" text="代表あいさつ" />}
        {device === "pc" && (
          <React.Fragment>
            <div className="Greeding__innerItem">
              <Title title="GREEDING" text="代表あいさつ" />

              <p className="Greeding__summary">
                私は凡人です。学生時代に運動部で大きな成績を残したこともなければ、組織の代表になったこともありません。
                <br />
                そんな私が何か強い想いを持っても、「凡人の戯言だ。」と言われたり、「自分なんて…」で片付けてしまう事すらあります。
                <br />
                <br />
                私は、SNSで何者かになれる「力」を手に入れる瞬間が大好きなのです。
                <br />
                「自分なんて…」から「自分にしかできない！」へ。そういう存在になれる「場所」なのです。
                <br />
                <br />
                皆、何かしらの夢があると思います。夢までいかなくとも、「海外で優雅に暮らしてみたい！」とか、「おしゃれなカフェを経営してみたいなぁ」とか。
                <br />
                「自分にしかできない！」へ。そういう存在になれる「場所」なのだ。
                <br />
                <br />
                皆、何かしらの夢ってある/あったと思います。夢までいかなくても、
                <br />
                これって本当に「素敵なコト」なんです。それが大人になるにつれて、「それ実現するのって現実的には難しいなぁ…」とか、「実力もないし、向いてないよなぁ…」とか。
                <br />
                本来のありたい姿を押し殺して、現実的な「社会」からの要求に応える方が良い/そうするしかない、と思ってる人がとても多いのではなかと感じます。
                <br />
                <br />
                しかし今、SNSは自分だけの「好き」を正しく発信していれば、それに共感してくれる人たちが集まってきてくれます。
                <br />
                集まる人が多くなれば多くなるほど、自分の創ったコミュニティは大きく強くなっていきます。
                <br />
                凡人でも、自分の本当に好きな世界で、ありのままの自分で価値提供ができるようになるのです。
                <br />
                <br />
                最期に「自分らしく人生を全うできたな」と、1人でも多くの人が思える世界を創りたい。
                <br />
                誰もが強い想い（ギフト）を持っているにもかかわらず、決して世に出ることがなかったモノに脚光を、自分を押し殺してしまうヒトたちに新たな手段を。
              </p>
            </div>

            <div className="Greeding__innerItem">
              <img
                src={`${process.env.IMAGE_PATH}greeding/pc/img-ceo.webp`}
                alt="倉島杏梨"
                loading="lazy"
                className="Greeding__image"
              />
            </div>
          </React.Fragment>
        )}
        {device === "sp" && (
          <React.Fragment>
            <img
              src={`${process.env.IMAGE_PATH}greeding/sp/img-ceo.webp`}
              alt="代表あいさつ"
              loading="lazy"
              className="Greeding__image"
            />
            <div className="Greeding__cardTitleWrapper">
              <TitleList title="ご挨拶" />
            </div>
            <Expand
              text={`私は凡人です。学生時代に運動部で大きな成績を残したこともなければ、組織の代表になったこともありません。\nそんな私が何か強い想いを持っても、「凡人の戯言だ。」と言われたり、「自分なんて…」で片付けてしまう事すらあります。\n\n私は、SNSで何者かになれる「力」を手に入れる瞬間が大好きなのです。\n「自分なんて…」から「自分にしかできない！」へ。そういう存在になれる「場所」なのです。\n\n皆、何かしらの夢があると思います。夢までいかなくとも、「海外で優雅に暮らしてみたい！」とか、「おしゃれなカフェを経営してみたいなぁ」とか。\nこれって本当に「素敵なコト」なんです。それが大人になるにつれて、「それ実現するのって現実的には難しいなぁ…」とか、「実力もないし、向いてないよなぁ…」とか。\n本来のありたい姿を押し殺して、現実的な「社会」からの要求に応える方が良い/そうするしかない、と思ってる人がとても多いのではなかと感じます。\n\nしかし今、SNSは自分だけの「好き」を正しく発信していれば、それに共感してくれる人たちが集まってきてくれます。\n集まる人が多くなれば多くなるほど、自分の創ったコミュニティは大きく強くなっていきます。\n凡人でも、自分の本当に好きな世界で、ありのままの自分で価値提供ができるようになるのです。\n\n最期に「自分らしく人生を全うできたな」と、1人でも多くの人が思える世界を創りたい。\n誰もが強い想い（ギフト）を持っているにもかかわらず、決して世に出ることがなかったモノに脚光を、自分を押し殺してしまうヒトたちに新たな手段を。`}
              addClassName="Greeding__expand"
            />
          </React.Fragment>
        )}
      </div>
    </Section>
  )
}

export default Greeding
